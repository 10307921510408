import Vue from 'vue'
import VueRouter from 'vue-router'
import paciente from '../views/paciente.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'paciente',
    component: paciente
  },
  {
    path: '/read/:idblog',
    name: 'read',
    component: () => import(/* webpackChunkName: "about" */ '../views/read.vue')
  },
  {
    path: '/sobre',
    name: 'sobre',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/sobre.vue')
  },
  {
    path: '/fiquePorDentro',
    name: 'fiquePorDentro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/fiquePorDentro.vue')
  },
  {
    path: '/descricaoEspecialidade/:id/:titulo',
    name: 'descricaoEspecialidade',
    component: () => import(/* webpackChunkName: "about" */ '../views/descricaoEspecialidade.vue')
  },
  {
    path: '/medico',
    name: 'medico',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/medico.vue')
  },
  {
    path: '/especialidades',
    name: 'especialidades',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/especialidades.vue')
  },
  // {
  //   path: '/paciente',
  //   name: 'paciente',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/paciente.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
