<template>
  <v-app>
    <div v-if=" !isObrigado && !isObrigado2 && intBreakPoint > 2 " class="d-flex flex-column justify-center">
    <a href="https://api.whatsapp.com/send?phone=5515991922634" target="_blank">
      <button
        style="z-index: 9999;     display: flex;
    line-height: 34px;"
        color="inherit"
        class="btnFloat"
      >
      <v-icon color="white" style="font-size:75px">mdi-whatsapp</v-icon>
       <span style="font-size: 22px; font-family: poppins; font-weight: 500; margin-top:22px">Entre em contato!</span>
       </button> 
       </a>
       </div>    

    <v-app-bar v-if="!isHome" app color="white" dark style="padding: 4px 0px !important; height: 70px">

      <v-spacer class=""></v-spacer>
      <div v-if="intBreakPoint > 2" style="display: contents">
        <div class="d-flex align-center">
          <router-link to="/">
            <v-img alt="Logo Solanute" class="shrink mt-1 mobileSize" contain src="./assets/icone.webp" max-width="50"
              style="left: -165px;" />
          </router-link>
        </div>
        <router-link v-for="(item, i) in itemsENG" :key="i" :to="{ name: item.path }"
          style="text-decoration: none !important; padding-left: 0px; padding-right: 0px;">
          <v-btn class=" btnMenu" style="
              font-family: poppins !important;
              left:200px;
              font-size: 16px;
              font-weight: 500 !important;
            ">{{ item.name }}</v-btn>
        </router-link>

        <router-link to="medico">
        <v-btn class="" style="
        background-color: transparent;
        border: 1px #1076bd solid;
        font-size: 19px;
        left:200px;
        text-transform: unset;
        color:#1076bd;
        font-weight: 600 !important">Seja um médico parceiro</v-btn>
        </router-link>
      </div>

      <div class="d-flex align-center" v-if="intBreakPoint < 2">
        <router-link to="/">
          <v-img alt="Logo Solanute" class="shrink mt-1 mobileSize" contain src="./assets/icone.webp" max-width="50" />
        </router-link>
      </div>

      <v-app-bar-nav-icon style="color: #000; margin-left: 230px !important" class="ml-3" v-if="intBreakPoint < 2 && !isHome"
        @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>

      <v-spacer class="DisableMobile"></v-spacer>
    </v-app-bar>

    <v-navigation-drawer v-if="!isHome" v-model="drawer" fixed temporary right style="background-color: #1076bd; opacity: 0.9">
      <v-list dense>
        <v-list-item-group v-model="group" color="primary">
          <router-link v-for="(item, i) in itemsENG" :key="i" :to="{ name: item.path }">
            <v-list-item class="my-10">
              <v-list-item-content>
                <v-list-item-title style="
                    text-decoration: none;
                    font-family: poppins;
                    font-size: 18px;
                    color: #fff;
                  " v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-item-group>
        <router-link to="medico">
        <p style="
          padding-left:15px;
        font-size: 18px;
        font-family: poppins;
        color:#fff;
        font-weight: 500 !important">Seja um médico parceiro</p>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-main style="padding-bottom: 0px !important; background-color:#F8F8F8">
      <router-view />
    </v-main>
    <v-row v-if="!isHome" width="100%" style="margin:0% !important">
      <v-col cols="12" md="12" style="padding-top:30px !important; margin:0px !important; padding: 0px !important ">
        <iframe class="wdMapa"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14634.78710416475!2d-47.4579296!3d-23.5074289!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c58a99ee963db1%3A0x28b34adcd4826ace!2sBoulevard%20Alavanca%20Business%20%26%20Care!5e0!3m2!1spt-PT!2sbr!4v1663852240188!5m2!1spt-PT!2sbr"
          width="100%" height="100%" style="border:0; padding:0px !important; margin:0px !important" allowfullscreen=""
          loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </v-col>
    </v-row>

    <v-footer v-if="!isHome" style="padding: 0px 0px !important; flex-direction: column;">
      <a v-if="intBreakPoint < 2" href="https://api.whatsapp.com/send?phone=5515991922634" target="_blank">
            <v-img class="whatsMobile" src="./assets/whats.webp" contain width="82" height="20 !important"
              style="position: fixed; bottom: 20px; right: 30px; z-index: 99;"></v-img>
        </a>
      <v-row
        style="background-color:#f4f4f4; height:54vh; padding-bottom:40px; padding:0px 80px; margin: 0px !important"
        class="hgMobile marginMobile padLeftMobile align-center justify-center d-flex">
        <v-col cols="12" md="12">
          <v-row class="align-center d-flex justify-center">
            <v-col cols="12" md="4" style=" padding-left:20px" class=" justify-center d-flex flex-column padLeftPaginas ">
              <p style="font-weight:800;  font-family: poppins;">Páginas</p>
              <router-link to="/">
                <p style="font-weight:400;  font-family: poppins;">Início</p>
              </router-link>
              <router-link to="sobre">
                <p style="font-weight:400;  font-family: poppins;">Sobre a Sonalute</p>
              </router-link>
              <!-- <router-link to="fiquePorDentro">
                <p style="font-weight:400;  font-family: poppins;">Fique por dentro</p>
              </router-link> -->
            </v-col>
            <v-col cols="12" md="3"></v-col>
            <!-- <v-col cols="12" md="4" style=" padding-left:20px !important"
          class=" justify-center d-flex flex-column">
          <p style="font-weight:800;  font-family: poppins;">Fique por dentro</p>
          <p style="font-weight:400;  font-family: poppins;">Lorem Ipsum</p>
          <p style="font-weight:400;  font-family: poppins;">Lorem Ipsum</p>
          <p style="font-weight:400;  font-family: poppins;">Lorem Ipsum</p>
        </v-col> -->
            <v-col cols="12" md="4" style=" padding-left:20px !important" class="justify-center d-flex flex-column">
                <p style="font-weight:800;  font-family: poppins; padding-left:33px; margin-bottom:0px">Contato</p>
              <a href="https://g.page/boulevard-alvanca-business-care?share" target="_blank">
              <div class="d-flex">
                <v-icon style="color:#000; padding-right:10px">mdi-map-marker</v-icon><p style="font-weight:400;  font-family: poppins;">Torre Business - Sala 103 <br>  R. Bernardo Guimarães, 105 - Jardim Vergueiro, Sorocaba - SP, 18030-030</p>
              </div>
              </a>
              <a href="tel:+551534110800">
              <p class="telMb" style="font-weight:400; "> <v-icon style="color:#000; padding-right:10px">mdi-phone</v-icon>(15) 3411-0800</p>
              </a>
              <a href="tel:+551534110800" target="_blank">
              <p class="telMb" style="font-weight:400; "> <v-icon style="color:#000; padding-right:10px">mdi-phone</v-icon>(15) 99192-2634</p>
              </a>
              <div>
               
                <a href="https://www.instagram.com/solanuteclinicamedica/" target="_blank">
                  <v-icon style="color:#000; padding:5px;" size="25"> mdi-instagram</v-icon>
                </a>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

     
    </v-footer>
    <v-footer v-if="!isHome" style=" padding: 0px 0px !important">
      <v-row style="margin:0px">
        <v-col cols="12" md="12" style="padding:0px !important">
          <v-row style="padding: 0px 80px; background-color: #000; height: 20vh; margin:-0px !important"
            class="padFooter">
            <v-col cols="12" md="6" class="align-center justify-center d-flex">
              <p style="color:#fff; font-weight:700; font-size:15px"> Solanute - Todos direitos reservados.
                <br> Site atualizado em 2022
              </p>
            </v-col>

            <v-col class=" align-center justify-center d-flex" cols="12" md="6">
              <a target="_blank" href="https://abaremarketing.com/">
                <v-img contain width="85" src="./assets/abareBranco.webp"></v-img>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>

    <v-dialog v-model="dialog" max-width="490" fullscreen>
      <v-card class="backgroundDialog">

        <v-btn class="" icon dark @click="dialog = false">
          <v-icon style="color:#fff">mdi-close</v-icon>
        </v-btn>

        <v-row style="height:90vh; margin:0px" class="align-center justify-center">
          <v-col class="offMb" cols="12" md="8">
            <v-card-title class="text-h5" style="
            font-family: Poppins !important;
            font-weight: 500;
            font-size: 32px !important;
            color: #fff;
            line-height: 1;
            text-align: center;
            word-break: inherit !important;
          ">
              Cadastre-se!</v-card-title>
            <div style="background-color: transparent; border-radius: 10px; padding: 0px 25px" class="paddingDialog">
              <v-text-field dark outlined v-model="contato.nome" label="Nome" required>
              </v-text-field>
              <v-text-field dark outlined v-model="contato.email" label="E-mail" required></v-text-field>
              <v-text-field dark outlined v-model="contato.senha" label="Senha" required></v-text-field>
              <v-text-field v-mask="'(##) #####-####'" dark outlined v-model="contato.telefone" :counter="15"
                label="Telefone" required></v-text-field>
              <v-text-field v-mask="'###.###.###-##'" dark outlined v-model="contato.cpf" :counter="14" label="CPF"
                required>
              </v-text-field>
              <v-btn @click="enviarFormulario" block class="mr-4" type="submit" style="
              background-color: #70a9b7;
              color: #fff;
              font-family: Poppins;
              font-weight: 300;
              border-radius: 5px;
              text-transform: inherit;
            ">
                Enviar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<style >
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}



@font-face {
  font-family: 'Bree Serif';

  src: url('./assets/fonts/BreeSerif-Regular.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'Bree Serif';

  src: url('./assets/fonts/BreeSerif-Regular.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: 'Bree Serif';

  src: url('./assets/fonts/BreeSerif-Regular.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'Bree Serif';

  src: url('./assets/fonts/BreeSerif-Regular.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'Bree Serif';

  src: url('./assets/fonts/BreeSerif-Regular.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}

.v-container {
  padding: 0 !important;
}

.btnFloat {
  position: fixed !important;
  right:-218px ;
  border-radius: 50px 0 0 50px !important;
  background-color: #34af23 !important;
  color: #ffffff !important;
  font-family: "Poppins" !important;
  top: 490px !important;
  padding: 15px 15px;
  font-weight: 300 !important;
}
.btnFloat:hover {
    right: -1px  !important;
    transition:all 0.3s ease !important;

}

@media (min-device-width: 340px) and (max-device-width: 731px) {
  .wdMapa {
    width: 99%;
  }

  .padLeftPaginas{
    padding-left: 50px !important;
  }

  .paddingDialog {
    padding: 0px 25px !important;
  }

  .v-application--is-ltr .offset-2 {
    margin-left: 0% !important;
  }

  .telMb {
    padding-right: 40px !important;
  }

  .padFooter {
    height: auto !important;
  }

  .hgMobile {
    height: auto !important;
  }

  .padLeftMobile {
    padding: 0px 50px ! important;
  }

  .marginMobile {
    margin-bottom: 90px !important;
  }

}

a {
  color: #000 !important;
  /* cor dos links */
  text-decoration: none;
  /* sem sublinhado */
}

.btnMenu {
  background-color: transparent !important;
  box-shadow: none !important;
  font-family: Open sans !important;
  font-weight: 400 !important;
  color: #000 !important;
  text-transform: initial !important;
}

.backgroundDialog {
  background-image: url("./assets/clinica.webp");
  background-repeat: no-repeat !important;
  background-size: cover;
  height: 500px;
  background-attachment: absolute;
  background-color: rgba(255, 255, 255, 0.596);
  background-position: center;
}
</style>

<script>

export default {
  name: "App",

  data: () => ({
    dialog: false,
    drawer: false,
    snackbar: false,
    text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
    group: {},
    contato: { nome: "", email: "", senha: "", telefone: "", cpf: "", },
    timeout: 2000,
    itemsENG: [
      // { name: "Mude o seu Futuro", path: "" },
      // { name: "Nossa Metodologia", path: "metodologia" },
      { name: "Início", path: "paciente" },
      // { name: "Lojas", path: "lojas" },
      { name: "Sobre a Solanute", path: "sobre" },
      // { name: "Fique por dentro", path: "fiquePorDentro" },
    ],
  }),

  mounted() {
    window.scrollTo(0, 0);
  },


  methods: {
    enviarFormulario() {
      fetch("https://gestao.abare.cloud/items/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id_formulario: 62, lead: this.contato }),
      })
        .then((response) => response.text())
        .then((data) => {
          window.console.log(data);
          // alert("contato enviado com sucesso");
          this.dialog = false;
          this.snackbar = true;
          this.enviarEmail()
        });
    },
  },

  computed: {
    isHome() {
      return this.$route.name == "paginaInicio";
    },

    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },
  },
};
</script>
