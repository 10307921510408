<template>
    <v-container fluid>
        <v-row class="bgTopo" data-aos="fade-down" data-aos-duration="1500">
            <v-col cols="12" md="6" class="align-center justify-center d-flex" data-aos="fade-up"
                data-aos-duration="1500">
                <h1 class="padTexto textoTitulo"
                    style="color: #fff; font-size:45px; font-weight:800; font-family: Bree serif; padding-left:60px; line-height:1">
                    A clínica feita pensada na sua saúde e conforto
                </h1>
            </v-col>
        </v-row>

        <v-row style="padding: 30px 0px">
            <v-col cols="12" md="6" class="d-flex flex-column bgCol" style="" data-aos="fade-right"
                data-aos-duration="1500">
                <h1 class="padTexto"
                    style="color:#5398a9; font-size:40px; font-weight:800; font-family: Bree serif; padding-left:60px; line-height:1">
                    Entre em contato conosco para agendar sua consulta
                </h1>
                <p class="padTexto" style="font-size:20px; padding-left:60px; padding-top:20px; font-family:Poppins">
                    Preencha o formulário para agendar sua consulta em nossa clínica, com um ambiente
                    confortável e profissionais altamente capacitados, a Solanute oferece uma estrutura confiável e um tratamento de primeira para os
                    seus pacientes.
                </p>
            </v-col>
            <v-col cols="12" md="6" class="align-center justify-center d-flex" data-aos="fade-up"
                data-aos-duration="1500">
                <div class="align-center d-flex justify-center positionMobile flex-column" style="border-radius: 30px;
                 padding: 80px 50px;
                 background-color: #4490a3;
                 box-shadow: none;
                 width: 500px;
                 height: 480px;">
                    <v-text-field background-color="#fff" v-model="contato.nome" style="border-radius:20px; width:500px"
                        outlined placeholder="Nome" required>
                    </v-text-field>
                    <v-text-field background-color="#fff" v-model="contato.email"
                        style="border-radius:20px; width:500px" outlined placeholder="E-mail" required>
                    </v-text-field>
                    <v-text-field background-color="#fff" v-model="contato.whatsapp"
                        style="border-radius:20px; width:500px" v-mask="'(##) #####-####'" outlined
                        placeholder="Whatsapp" required>
                    </v-text-field>
                    <v-select :items="items" background-color="#fff" v-model="contato.especialidade"
                        style="border-radius:20px; width:500px" placeholder="Especialidade" outlined></v-select>
                    <v-btn :disabled="loading" @click="enviarFormulario" class="mr-4" type="button" height="50px" style="background-color: #fff; color: #4490a3; 
            font-family: Bree Serif, Poppins; font-weight: 600; font-size:15px; border-radius: 30px;
            text-transform: inherit; margin-top: 25px; margin-right: 0px !important;
            width:200px;"> Enviar
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-row class="flexMobile" style="background-color:#135c6ef0; height:30vh" data-aos="fade-up"
            data-aos-duration="1500">
            <v-col cols="12" md="6" class="align-center d-flex justify-center padCol " style="padding:60px">
                <h2 class="textoCorpo padTexto"
                    style="color:#fff; font-size:35px; font-weight:800; font-family: Bree serif; padding-left:60px; line-height:1">
                    A medicina é o remédio para todas as dores humanas</h2>
            </v-col>
            <v-col cols="12" md="6" class="align-center d-flex justify-center">
                <v-img class="wdImgMobile" contain style="height:160px" src="../assets/passoApasso.webp"></v-img>
            </v-col>
        </v-row>



        <v-row class="especialidade bgEspeciali padBorder"  style="">

            <v-col cols="12" md="12" class="align-center d-flex justify-center" style="padding-bottom:30px">
                <h1 class="padTexto"
                    style="color:#fff; font-size:38px; font-family: bree serif, Poppins; line-height: 1;">
                    Especialidades oferecidas
                    na
                    Solanute</h1>
            </v-col>
            <v-col cols="12" md="12" class="d-flex  align-center justify-center "  data-aos="fade-up" data-aos-duration="1800">
                <v-col class="d-flex align-center justify-center " v-for="logos in especiSolanute" :key="logos" cols="6" md="6">
                        
                        <v-card class="align-center sizeMob justify-center flex-column d-flex"
                            style="background-color:transparent; width: 450px; border-radius:20px; box-shadow:none">
                            <v-img style="flex: initial" contain height="130px"
                                :src="`https://gestao.abare.cloud/assets/${logos.logo}`"></v-img>
                            <div style="padding-bottom:25px" class="align-center justify-center flex-column d-flex">
                                <div style="min-height: 81px; max-height: 81px" class="alinhadoM">
                                <p style="color:#fff; font-size: 20px; font-weight: 800; padding:25px 25px; text-align:center ">{{ logos.titulo }}</p>
                                </div>
                                <router-link :to="'/descricaoEspecialidade/' + logos.id + '/' + logos.titulo">
                                    <v-btn style="color:#4490a3; background-color: #E6E6E6; font-size:15px; font-weight:800; ">
                                        Saiba mais
                                    </v-btn>
                                </router-link>
                            </div>
                        </v-card>
                    </v-col>
            </v-col>
                <!-- <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
                    style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:450">
                    <v-img class="wdEsp" style="border-radius:20px" src="../assets/pedia.webp" height="130px" contain>
                    </v-img>
                    <v-card-title style="color: #fff; font-size: 25px">
                        Pediatria
                    </v-card-title>
                    <a href="solanute.com.br/descricaoEspecialidade/1/pediatria">
                    <v-btn style="text-transform: none; font-family: Poppins; font-size:15px; border-radius:20px; background-color:#E6E6E6; border:solid 1px #4490a3; color:#4490a3">Saiba mais</v-btn>
                    </a>
                </v-card>
            </v-col>
            <v-col cols="6" md="6" data-aos="fade-up" data-aos-duration="1800">
                <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
                    style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:450">
                    <v-img class="wdEsp" style="border-radius:20px" src="../assets/alergologia.png" height="130px" contain>
                    </v-img>
                    <v-card-title style="color: #fff; font-size: 25px">
                        Alergologia
                    </v-card-title>
                    <a href="solanute.com.br/descricaoEspecialidade/2/alergologia">
                    <v-btn style="text-transform: none; font-family: Poppins; font-size:15px; border-radius:20px; background-color:#E6E6E6; border:solid 1px #4490a3; color:#4490a3">Saiba mais</v-btn>
                    </a>
                </v-card>
            </v-col> -->


            <!-- <v-col cols="6" md="3" data-aos="fade-up" data-aos-duration="2000">
                <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
                    style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
                    <v-img class="wdEsp" style="border-radius:20px" src="../assets/cardio.webp" height="80px" contain>
                    </v-img>
                    <v-card-title style="color: #fff; font-size: 18px">
                        Cardiologia
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="6" md="3" class="d-flex" data-aos="fade-up" data-aos-duration="2200">
                <v-card class="d-flex align-center justify-center flex-column  widthCard mx-auto"
                    style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
                    <v-img class="wdEsp" style="border-radius:20px" src="../assets/fisio.webp" height="80px" contain>
                    </v-img>
                    <v-card-title style="color: #fff; font-size: 18px">
                        Fisioterapia
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="6" md="3" class="d-flex" data-aos="fade-up" data-aos-duration="2200">
                <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
                    style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
                    <v-img class="wdEsp" style="border-radius:20px" src="../assets/neuro.webp" height="80px" contain>
                    </v-img>
                    <v-card-title style="color: #fff; font-size: 18px">
                        Neurologia
                    </v-card-title>
                </v-card>
            </v-col>


            <v-col cols="6" md="3" data-aos="fade-up" data-aos-duration="1800">
                <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
                    style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
                    <v-img class="wdEsp" style="border-radius:20px" src="../assets/ortopedia.webp" height="80px"
                        contain></v-img>
                    <v-card-title style="color: #fff; font-size: 18px">
                        Ortopedia
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="6" md="3" data-aos="fade-up" data-aos-duration="2000">
                <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
                    style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
                    <v-img class="wdEsp" style="border-radius:20px" src="../assets/pneumologia.webp" height="80px"
                        contain>
                    </v-img>
                    <v-card-title style="color: #fff; font-size: 18px">
                        Pneumologia
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="6" md="3" class="d-flex" data-aos="fade-up" data-aos-duration="2200"
                style="padding-bottom:40px">
                <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
                    style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344">
                    <v-img class="wdEsp" style="border-radius:20px" src="../assets/dermatology.webp" height="80px"
                        width="90px" contain></v-img>
                    <v-card-title style="color: #fff; font-size: 18px">
                        Dermatologia
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col cols="6" md="3" class="d-flex" data-aos="fade-up" data-aos-duration="2200">
                <v-card class="d-flex align-center justify-center flex-column widthCard  mx-auto"
                    style="border-radius:20px; background-color: transparent !important; box-shadow: none; max-width:344;">
                    <v-img class="wdEsp" style="border-radius:20px" src="../assets/plastica.webp" height="80px" contain>
                    </v-img>
                    <v-card-title style="color: #fff; font-size: 18px">
                        Cirurgia <br> Plástica
                    </v-card-title>
                </v-card>
            </v-col> -->
        </v-row>

        <v-row style="padding-top:50px; background-color: #5398a92b;">
            <v-col cols="12" md="6" class=" d-flex flex-column justify-center  " style="" data-aos="fade-right"
                data-aos-duration="1500">
                <h1 class="padTexto"
                    style="color:#5398a9; font-size:40px; font-weight:800; font-family: Bree serif; padding-left:60px; line-height:1">
                    O amor pelo próximo é o<br>melhor cuidado
                </h1>
                <p class="padTexto"
                    style="font-size:20px; padding-left:60px; padding-top:20px; padding-right:60px; font-family:Poppins">
                    A Solanute é uma clínica que oferece consultas e
                    exames a preços acessíveis, no dia em que você precisa e com serviços de qualidade. Tudo em um único
                    lugar!
                    Por aqui, os pacientes são atendidos com cuidado e de modo personalizado, em um ambiente completo
                    com excelente estrutura e conforto.
                </p>
            </v-col>
            <v-col cols="12" md="6" class="align-center justify-center d-flex padImg1" style="padding-right:60px"
                data-aos="fade-up" data-aos-duration="1500">
                <v-img style="border-radius:800px" contain src="../assets/consultorio.png"></v-img>
            </v-col>
        </v-row>

        <v-row style="padding-top:50px; padding-bottom:50px; background-color: #5398a92b;">
            <v-col cols="12" md="6" class="align-center justify-center d-flex padImg2 " style="padding-left:60px"
                data-aos="fade-up" data-aos-duration="1500">
                <v-img class="" style="border-radius:800px" src="../assets/atendimento.webp"></v-img>
            </v-col>
            <v-col cols="12" md="6" class=" d-flex flex-column justify-center " style="" data-aos="fade-right"
                data-aos-duration="1500">
                <h1 class="padTexto"
                    style="color:#5398a9; font-size:40px; font-weight:800; font-family: Bree serif; padding-left:60px; line-height:1">
                    Atendimento humano e empático
                </h1>
                <p class="padTexto"
                    style="font-size:20px; padding-left:60px; padding-top:20px; padding-right:60px; font-family:Poppins">
                    A nossa empatia fica evidente em cada atendimento. A nossa missão é superar
                    as expectativas, tornando a experiência do paciente mais confortável possível.
                </p>

            </v-col>
        </v-row>

        <v-row class="bgForm" data-aos="fade-up" data-aos-duration="1500">
            <v-col cols="12" md="12" class="justify-center d-flex align-center flex-column" style="padding-top:30px">
                <h1 class="padTexto"
                    style="color:#fff; font-size:35px; font-weight:800; font-family: Bree serif; padding-left:0px; padding-bottom:20px; line-height:1">
                    Faça seu agendamento agora:</h1>
                <div class="align-center d-flex justify-center positionMobile flex-column" style="border-radius: 30px;
                 padding: 80px 50px;
                 background-color: #4490a3;
                 box-shadow: none;
                 opacity:80%;
                 width: 40%;
                 height: 100%;">
                    <v-text-field background-color="#fff" v-model="contato.nome"
                        style="border-radius:20px; width:500px;" outlined placeholder="Nome" required>
                    </v-text-field>
                    <v-text-field background-color="#fff" v-model="contato.email"
                        style="border-radius:20px; width:500px" outlined placeholder="E-mail" required>
                    </v-text-field>
                    <v-text-field background-color="#fff" v-model="contato.whatsapp"
                        style="border-radius:20px; width:500px" v-mask="'(##) #####-####'" outlined
                        placeholder="Whatsapp" required>
                    </v-text-field>
                    <v-select :items="items" background-color="#fff" v-model="contato.especialidade"
                        style="border-radius:20px; width:500px" placeholder="Especialidade" outlined></v-select>
                    <v-btn @click="enviarFormulario" class="mr-4" type="button" height="50px" style="background-color: #fff; color: #4490a3; 
            font-family: Bree Serif, Poppins; font-weight: 600; font-size:15px; border-radius: 30px;
            text-transform: inherit; margin-top: 25px; margin-right: 0px !important;
            width:200px;"> Enviar
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-snackbar v-model="snackbar" :timeout="timeout">
            {{ text }}

            <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>

    </v-container>
</template>

<script>
export default {
    data: () => ({
        contato:
            { nome: '', email: '', whatsapp: '', especialidade: '' },
        snackbar: false,
        especiSolanute: [],
        loading: false,
        text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
        timeout: 2000,
        items: ['Pediatria', 'Alergologia'],
    }),

    created() {
        fetch(
            "https://gestao.abare.cloud/items/especisolanute"
        )
            .then((response) => response.json())
            .then((data) => {
                this.especiSolanute = data.data;
            });

    },

    methods: {
        enviarFormulario() {
            if ((this.contato.nome === "")) { 
                    alert("Por favor preencha todos os campos")
                    return;
                }
                else if ((this.contato.email === "")){
                    alert("Por favor preencha todos os campos")
                    return;
                }
                else if ((this.contato.whatsapp === "")){
                    alert("Por favor preencha todos os campos")
                    return;
                }
                else if ((this.contato.especialidade === "")){
                    alert("Por favor preencha todos os campos")
                    return;
                }
            else{
            this.loading = true;
            fetch("https://gestao.abare.cloud/items/leads", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({ id_formulario: 82, lead: this.contato }),
            })
                .then((response) => response.text())
                .then((data) => {
                    window.console.log(data);
                    // alert("contato enviado com sucesso");
                    this.snackbar = true;
                    this.enviarEmail();
                });
        }
    },

        enviarEmail() {
            fetch("https://gestao.abare.cloud/email/gen-send", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    novo: true,
                    template: "d-6f0c80f846af4963a599785c85ccef8e",
                    email: 'recepcao@solanute.com.br',
                    dynamic: {
                        nome: this.contato.nome,
                        email: this.contato.email,
                        whatsapp: this.contato.whatsapp,
                        especialidade: this.contato.especialidade,
                    }
                }),
            })
                .then((response) => response.text())
                .then(() => {
                    //this.$router.push("obrigado");
                    this.r = {};
                });
        },
    },

 
}
</script>

<style scoped>
@media (min-device-width: 340px) and (max-device-width: 820px) {

    .bgTopo {
        background-image: url("../assets/pacienteBannerMobile.webp") !important;

    }

    .positionMobile {
        position: relative !important;
        right: 0 !important;
        top: initial !important;
        padding: 50px 30px !important;
        width: 360px !important;
    }

    .textoTitulo {
        font-size: 40px !important;
        padding-top: 350px !important;
    }

    .padTexto {
        padding-left: 10px !important;
    }

    .bgCol {
        background-image: none !important;
    }

    .textoCorpo {
        font-size: 25px !important;
        padding: 30px 30px 0px 30px !important;
    }

    .flexMobile {
        height: 40vh !important;
        /* flex-direction: column !important; */
    }

    .especialidade {
        height: 100% !important;
    }

    .widthCard {
        max-width: auto !important;
    }

    .wdEsp {
        width: 85vw !important;
    }

    .padCol {
        padding: 0px !important;
    }

    .padImg1 {
        padding-right: 20px !important;
        padding-left: 20px !important;

    }

    .padImg2 {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .wdImgMobile {
        min-width: 350px !important;

    }
}

.bgTopo {
    background-image: url("../assets/pacienteBanner.webp");
    height: 80vh !important;
    background-attachment: inherit !important;
    background-size: cover !important;

}

.bgEspeciali {
    background-image: url("../assets/hospital.webp") !important;
    height: 434px ;
    background-attachment: inherit !important;
    background-size: cover !important;
    background-position: center;
}

.bgCol {
    background-image: url("../assets/bgCol.webp");
    /* height: 80vh !important; */
    background-attachment: inherit !important;
    width: auto;
    background-size: contain !important;
    background-position: center;
}

.bgForm[data-v-5c26cd26]{
    background-image: url("../assets/fundoMedicosForm.webp") !important;
    height: 100% !important;
    background-attachment: inherit !important;
    background-size: cover !important;
    opacity: 85% !important;
}
.bgForm{
    background-image: url("../assets/fundoMedicosForm.webp") !important;
    height: 100% !important;
    background-attachment: inherit !important;
    background-size: cover !important;
    opacity: 0.85 !important;
}
</style>